/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import type ApiRequestConfig from './api-request-config';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import type CustomerDataVerificationDescriptors from './descriptors';

export default class ContextualIdentifierConfig extends Fragment {
  @attr('string') declare name: string;
  @fragment('workflow-connector/customer-data-verification/api-request-config')
  declare apiRequestConfig: ApiRequestConfig;
  @fragmentArray('workflow-connector/customer-data-verification/descriptors')
  declare descriptors: CustomerDataVerificationDescriptors[];
}
