/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import type ActionHeader from '../action-header';
import type TestResult from './test-result';
import type MockResponse from './mock_response';

export default class ApiRequestConfig extends Fragment {
  @attr('string') declare url: string;
  @attr('string') declare httpMethod: string;
  @attr('string') declare body: string | null;
  @attr('string') declare tokenId: string | null;
  @fragmentArray('workflow-connector/action-header') declare headers: ActionHeader[];
  @fragmentArray('workflow-connector/customer-data-verification/test-result')
  declare testResults: TestResult[];
  @fragmentArray('workflow-connector/customer-data-verification/mock-response')
  declare mockResponses: MockResponse[];
}
